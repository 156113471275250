import { useLoaderData, useLocation } from '@remix-run/react';
import { ClientOnly } from 'remix-utils/client-only';
import type { Feed, ScreenType } from '~/services/layout/index.ts';
/* COMPONENTS */
import GeneralContent from '~/plugins/general-content/components/index.tsx';
import NavBar from '~/components/navigation/nav-bar.tsx';
import NewNavBar, {
  getCurrentNavBar,
} from '~/services/plugins/nav-bar/components';
import WebView from '~/components/screens/web-view.tsx';
import NetworkStatusBanner from '~/components/network-status-banner.tsx';
import Footer from '~/components/navigation/footer.tsx';
import HookLoading from '~/components/hook-loading.tsx';
/* TYPES */
import type { MetaFunction, HeadersFunction } from '@remix-run/node';
import { useScreenAnalytics } from '~/hooks/use-screen-analytics.ts';
import {
  cssVarsMetaFunctionData,
  getThemeVars,
} from '~/services/css-vars/index.ts';
import { useScreenHooks } from '~/hooks/use-screen-hooks.ts';

import type { LoaderFunctionArgs } from '@remix-run/node';
import { json, redirect } from '@remix-run/node';
import {
  getLinksToScreenData,
  getScreenIdByScreenType,
} from '~/services/layout/index.server.ts';
import { getSearchScreens } from '~/services/layout/index.ts';
import { Cause, Effect, Exit, Option } from 'effect';
import { getDebugMode } from '~/services/debug/index.server.ts';
import { maybeLoginWithToken } from '~/services/login/index.ts';
import type { GetSearchScreenData } from '~/services/router/index.server';
import {
  getIsSearchScreen,
  getSearchScreenData,
  getStaticLinks,
  getWebviewUrl,
} from '~/services/router/index.server';
import {
  addWebLinks,
  getCurrentScreen2,
  maybeRedirectToPlayer,
} from '~/services/router/index.server';
import { getHash } from '~/utils/hash.ts';
import type { NavigationHandler } from '~/services/layout/navigations.ts';
import {
  getNavItems,
  getScreenNavigations,
} from '~/services/layout/navigations.ts';
import type { FooterData } from '~/components/navigation/footer.tsx';
import type { ServerLoadedFeeds } from '~/services/layout/server-loaded-feeds.ts';
import { type Screen } from '~/services/layout/index.ts';
import { getServerLoadedFeeds } from '~/services/layout/server-loaded-feeds.ts';
import { getBaseURL } from '~/utils/get-base-url.server.ts';
import getMetadata from '~/services/seo/get-metadata/index.ts';
import { getConfig, getConfigsCaching } from '~/services/config/index.ts';
import { ZappConfig } from '~/services/zapp-config/index.server.ts';
import { getAnalyticsSharedVars } from '~/services/analytics/shared-vars.server.ts';
import { isValidUrl } from '~/utils/validations.ts';
import type { ZappNavigation } from '~/services/layout/types';
import { isThemeV2Enabled } from '~/services/css-vars/get-theme-vars';
import { RequestURL, ScreenReuqest } from '~/services/request-url';
import { getPluginConfig } from '~/utils/get-plugin-configuration';
import webPocManifest from '~/services/plugins/web-poc/config/manifest';
import cachingManifest from '~/services/plugins/caching/config/manifest';
import userwayManifest from '~/services/plugins/userWay/config/manifest';
import oneTrustManifest from '~/services/plugins/applicaster-cmp-onetrust/config/manifest';
import favoritesManifest from '~/services/plugins/local_storage_favourites_action/config/manifest';
import continueWatchingManifest from '~/services/plugins/continue-watching/config/manifest';
import defaultThemeV2 from '~/services/plugins/default_theme_v2/config/manifest';
import navigationActionManifest from '~/services/plugins/navigation_action/config/manifest.ts';
import trailerActionManifest from '~/services/plugins/trailer_action/config/manifest.ts';
import {
  getDeviceType,
  getTimeZoneHeader,
} from '~/services/request/index.server';
import { useScreensHistory } from '~/hooks/use-screens-history';
import { useLoadIsLoggedIn, useMockIsLoggedIn } from '~/hooks/use-is-logged-in';
import { useLoadFeed } from '~/hooks/use-feed';
import { transformSmartComponents } from '~/services/plugins/curation/components';
import { useLanguageRedirect } from '~/hooks/use-language-redirect.ts';
import { getSupportedLanguages, getURLLanguage } from '~/utils/i18n';

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const returnedHeaders: any = {};
  const cacheControl: string | null = loaderHeaders.get('Cache-Control');
  if (cacheControl) returnedHeaders['Cache-Control'] = cacheControl;
  const setCookie: string | null = loaderHeaders.get('Set-Cookie');
  if (setCookie) returnedHeaders['Set-Cookie'] = setCookie;

  return returnedHeaders;
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  const metaTags: any[] = [
    ...cssVarsMetaFunctionData({
      data,
      routePath: 'css-vars',
    }),
    ...cssVarsMetaFunctionData({
      data,
      routePath: 'css-vars-v2',
    }),
    {
      tagName: 'link',
      rel: 'canonical',
      href: data?.canonicalUrl,
    },
    ...(data?.metadata || []),
  ];

  if (data?.isSearchScreen) {
    metaTags.push({
      name: 'robots',
      content: 'noindex',
    });
  }

  return metaTags;
};

export async function loader(loaderFunctionArgs: LoaderFunctionArgs) {
  const { request } = loaderFunctionArgs;
  const url: URL = new URL(request.url);

  const supportedLanguages: string[] = getSupportedLanguages();
  const urlLanguage: string | 'unknown' = getURLLanguage(url.pathname);

  if (
    urlLanguage === supportedLanguages[0] &&
    url.pathname.startsWith(`/${urlLanguage}`)
  ) {
    const pathname = url.pathname.replace(`/${urlLanguage}`, '');
    return redirect(pathname, { status: 301 });
  }

  maybeLoginWithToken(url);

  const { debug, debugLayoutId } = getDebugMode(url);

  const program = Effect.gen(function* (_) {
    const url = yield* _(RequestURL);
    const {
      screens,
      contentTypes,
      endpoints,
      pluginConfigurations,
      cellStyles,
      navigations,
      analyticsSharedVars,
      presetsMapping,
    } = yield* _(ZappConfig);

    const themeVars = getThemeVars(pluginConfigurations);

    const hash = getHash(
      JSON.stringify({
        screens,
        cellStyles,
        themeVars,
        navigations,
        APP_VERSION_UUID: process.env.APP_VERSION_UUID,
        programmatic: process.env.PROGRAMMATIC ? Date.now() : false,
      })
    );

    const pathname: string = url.pathname;

    const q: string | null = url.searchParams.get('q');

    const { screen, status, screenEntry } = yield* _(
      getCurrentScreen2(supportedLanguages, urlLanguage)
    );

    const { customConfiguration: webPoc } = getPluginConfig<
      typeof webPocManifest
    >(webPocManifest, pluginConfigurations);

    const trailerSource = webPoc?.trailerSource;

    yield* _(
      maybeRedirectToPlayer({
        screenEntry,
        trailerSource,
        language: urlLanguage,
      })
    );

    const isSearchScreen = getIsSearchScreen(pathname);

    const searchData: GetSearchScreenData = yield* _(
      Effect.promise(() => getSearchScreenData(pathname, screens, q))
    );

    const screenNavigations: NavigationHandler = getScreenNavigations({
      navigations,
      screens,
      currentScreen: screen,
      urlLanguage,
    });

    const footerData: { footerNavItems: FooterData[]; id: string } | undefined =
      getNavItems({
        navigations,
        navigationType: 'footer',
        screens,
        urlPathname: isSearchScreen ? `/${screen.id}` : url.pathname,
      });

    const uiComponents = screen.ui_components;

    const smartComponents = yield* _(
      Effect.promise(() =>
        transformSmartComponents({
          uiComponents,
          presetsMapping,
          pluginConfigurations,
          endpoints,
          request,
        })
      )
    );

    let originEntryUrl: string | undefined;

    if (url.searchParams.has('screen-feed')) {
      originEntryUrl = url.searchParams.get('screen-feed') || undefined;
    }

    const serverLoadedFeeds: ServerLoadedFeeds = (yield* _(
      Effect.promise(() =>
        getServerLoadedFeeds({
          uiComponents: smartComponents,
          dynamicIdFallback: screenEntry?.id,
          q,
          screen,
          endpoints,
          pluginConfigurations,
          request,
          screenFeedEntry: screenEntry || {},
        })
      )
    )).map((serverLoadedFeed) => {
      if (serverLoadedFeed?.feed) {
        return {
          ...serverLoadedFeed,
          feed: addWebLinks({
            feed: serverLoadedFeed?.feed,
            contentTypes,
            screens,
            trailerSource,
            originEntryUrl,
            language: urlLanguage,
            supportedLanguages,
          }),
        };
      }
      return serverLoadedFeed;
    });

    const searchScreens: Screen[] | undefined = getSearchScreens(screens);

    const searchScreenId: string | undefined = getScreenIdByScreenType(
      searchScreens,
      ['search_v2', 'qb_search_screen']
    );

    const baseURL = getBaseURL();

    const linksToScreenData = getLinksToScreenData(screens);

    const metadata = getMetadata(url, screen.general, {
      entry: [screenEntry],
    } as Feed);

    const hasScreenHooks = screen?.hooks?.preload_plugins?.length > 0;

    const { customConfiguration: userway } = getPluginConfig<
      typeof userwayManifest
    >(userwayManifest, pluginConfigurations);

    const { userway_account_id: userwayAccountId } = userway;

    const { customConfiguration: oneTrust } = getPluginConfig<
      typeof oneTrustManifest
    >(oneTrustManifest, pluginConfigurations);

    const { one_trust_id: oneTrustId } = oneTrust;

    const analyticsCustomProperties =
      screenEntry.extensions?.analyticsCustomProperties &&
      JSON.stringify(screenEntry.extensions?.analyticsCustomProperties);

    const analyticsTrackData = {
      ...analyticsSharedVars,
      screen_name: screen.name,
      screen_type: screen.type,
      screen_title: screen.title,
      home_screen: screen.home,
      screen_entry_title: screenEntry.title,
      screen_entry_id: screenEntry.id,
      screen_entry_type: screenEntry.value?.type,
      screen_layout_id: screen.id,
      analyticsCustomProperties,
    };

    const favoritesAction = getPluginConfig<typeof favoritesManifest>(
      favoritesManifest,
      pluginConfigurations
    );

    const { synced_feed: favoritesSyncedFeed } =
      favoritesAction?.customConfiguration;

    const isMock: boolean | undefined = process.env?.MOCK ? true : false;
    const DISABLE_THIRD_PARTY_SCRIPTS: string | undefined =
      process.env?.DISABLE_THIRD_PARTY_SCRIPTS;

    const isFavoritesSyncedFeed: boolean =
      isMock || isValidUrl(favoritesSyncedFeed?.source);

    const { customConfiguration: cw } = getPluginConfig<
      typeof continueWatchingManifest
    >(continueWatchingManifest, pluginConfigurations);

    const {
      synced_feed: continueWatchingSyncedFeed,
      auto_resume_maximum_items: maximumCWentries,
    } = cw;

    const isContinueWatchingSyncedFeed: boolean =
      isMock || isValidUrl(continueWatchingSyncedFeed?.source);

    const canonicalUrl: string = `${baseURL}${url.pathname}`;

    const newNavigations = Object.entries(navigations).reduce(
      (acc, [id, nav]) => {
        acc[id] = nav as ZappNavigation;
        return acc;
      },
      {} as Record<string, ZappNavigation>
    );

    const searchScreen: Screen | undefined = searchScreens?.[0];

    const navBar = getCurrentNavBar(
      isSearchScreen && searchScreen ? searchScreen : screen,
      newNavigations
    );

    const webviewUrl = yield* _(
      getWebviewUrl({
        screen,
        request,
        endpoints,
        pluginConfigurations,
      })
    );

    const isThemeV2: boolean = isThemeV2Enabled(pluginConfigurations);

    const { customConfiguration: caching } = getPluginConfig<
      typeof cachingManifest
    >(cachingManifest, pluginConfigurations);

    const { page_cache_max_age, page_cache_shared_max_age } = caching;

    const { customConfiguration: defaultThemeV2Config } = getPluginConfig<
      typeof defaultThemeV2
    >(defaultThemeV2, pluginConfigurations);

    const timeZoneHeader: string | null = getTimeZoneHeader(request);

    const navigationAction = getPluginConfig<typeof navigationActionManifest>(
      navigationActionManifest,
      pluginConfigurations,
      false,
      urlLanguage
    );

    const cacheControl =
      ['web-products'].includes(screen.type) ||
      [true, 'true'].includes(screen?.general?.disable_cache_control)
        ? 'no-store, no-cache, must-revalidate'
        : `max-age=${page_cache_max_age}, s-maxage=${page_cache_shared_max_age}`;

    const trailerAction = getPluginConfig<typeof trailerActionManifest>(
      trailerActionManifest,
      pluginConfigurations,
      true
    );

    const deviceType = getDeviceType(request);

    return json(
      {
        metadata,
        trailerSource,
        baseURL,
        canonicalUrl,
        cellStyles,
        contentTypes,
        linksToScreenData,
        debug,
        navBar,
        navigations: screenNavigations,
        footerData,
        screen,
        searchScreens,
        searchScreenId,
        isSearchScreen,
        searchData,
        serverLoadedFeeds,
        isMock,
        hash,
        debugLayoutId,
        hasScreenHooks,
        uiComponents: smartComponents,
        oneTrustId,
        userwayAccountId,
        analyticsTrackData,
        isFavoritesSyncedFeed,
        isContinueWatchingSyncedFeed,
        webviewUrl,
        isThemeV2,
        defaultThemeV2Config,
        staticLinks: getStaticLinks(screens, supportedLanguages, urlLanguage),
        timeZoneHeader,
        navigationAction,
        trailerAction,
        favoritesAction,
        maximumCWentries,
        deviceType,
        supportedLanguages,
        language: urlLanguage,
        DISABLE_THIRD_PARTY_SCRIPTS,
      },
      {
        status,
        headers: {
          'Cache-Control': cacheControl,
        },
      }
    );
  });

  const runnable = program.pipe(
    Effect.provideService(ZappConfig, await getZappConfig(debugLayoutId)),
    Effect.provideService(RequestURL, new URL(request.url)),
    Effect.provideService(ScreenReuqest, request)
  );

  const failureOrSuccess = await Effect.runPromiseExit(runnable);

  return Exit.match(failureOrSuccess, {
    onSuccess: (value) => {
      return value;
    },
    onFailure: (cause) => {
      const failure = Option.getOrUndefined(Cause.failureOption(cause));

      if (failure?._tag === 'No404ScreenSetError') {
        throw new Response(null, {
          status: 404,
          statusText: 'Not Found',
        });
      }

      if (failure?._tag === 'RedirectError') {
        throw redirect(failure?.redirectPath, { status: 302 });
      }
      throw cause;
    },
  });
}

async function getZappConfig(debugLayoutId: string | null | undefined) {
  const { ttl, swr } = getConfigsCaching();

  const {
    layout,
    pluginConfigurations,
    endpoints,
    cellStyles,
    presetsMapping,
  } = await getConfig({ ttl, swr }, debugLayoutId);

  const analyticsSharedVars = await getAnalyticsSharedVars(layout.id);

  const {
    screens,
    content_types: contentTypes,
    navigations,
    page_not_found,
  } = layout;
  return {
    screens,
    contentTypes,
    cellStyles,
    endpoints,
    pluginConfigurations,
    navigations,
    pageNotFoundScreenId: page_not_found,
    analyticsSharedVars,
    presetsMapping,
  };
}

export default function Index(): JSX.Element {
  const data = useLoaderData<typeof loader>();

  const {
    debug,
    screen,
    hasScreenHooks,
    oneTrustId,
    userwayAccountId,
    analyticsTrackData,
    isMock,
    supportedLanguages,
    language,
    DISABLE_THIRD_PARTY_SCRIPTS,
    isFavoritesSyncedFeed,
    isContinueWatchingSyncedFeed,
  } = data;

  useScreensHistory();

  const location = useLocation();

  useLanguageRedirect(supportedLanguages, language);

  const isHookLoading: boolean = useScreenHooks(screen.id, hasScreenHooks);

  useLoadIsLoggedIn();
  useMockIsLoggedIn(isMock);

  useLoadFeed('favorites', isFavoritesSyncedFeed);
  useLoadFeed('continue-watching', isContinueWatchingSyncedFeed);

  useScreenAnalytics(analyticsTrackData);

  if (isHookLoading) return <HookLoading />;

  const screenType: ScreenType = screen.type;

  return (
    <div key={location.pathname + location?.search} className="screen-opacity">
      {!DISABLE_THIRD_PARTY_SCRIPTS && !!userwayAccountId && (
        <script
          src="https://cdn.userway.org/widget.js"
          data-account={userwayAccountId}
          async
          defer
        ></script>
      )}

      {!DISABLE_THIRD_PARTY_SCRIPTS && !!oneTrustId && (
        <>
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            data-domain-script={oneTrustId}
          ></script>

          <script
            dangerouslySetInnerHTML={{
              __html: `function OptanonWrapper() {}`,
            }}
          />
        </>
      )}

      <ClientOnly fallback={<></>}>{() => <NetworkStatusBanner />}</ClientOnly>
      {debug && <div className="fixed z-10 h-1 w-full bg-orange-500"></div>}

      <NavBar />
      <NewNavBar />

      {[
        'general_content',
        'search_v2',
        'qb_search_screen',
        'web-products',
      ].includes(screenType) && <GeneralContent />}

      {screenType === 'webview_screen_qb' && <WebView />}

      <Footer />
    </div>
  );
}
