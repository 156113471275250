import { useFetcher } from '@remix-run/react';
import localforage from 'localforage';
import { useEffect, useState } from 'react';
import { useIsLoggedIn } from './use-is-logged-in';
import type { Feed } from '~/services/layout/index';

export function useLoadFeed(apiEndpoint: string, enabled: boolean): void {
  const fetcher = useFetcher({
    key: apiEndpoint,
  });

  const { isLoggedIn } = useIsLoggedIn();

  useEffect(() => {
    if (!isLoggedIn || !enabled) return;

    if (fetcher.state === 'idle' && !fetcher.data) {
      fetcher.load(`/api/${apiEndpoint}`);
    }
  }, [isLoggedIn, enabled]);
}

export function useGetStorageFeed({
  storageKey,
  condition,
}: {
  storageKey: 'continue-watching' | 'favorites';
  condition: boolean;
}): {
  feed: Feed | undefined;
  feedCount: number;
  isLoading: boolean;
  isUpdating: boolean;
} {
  const fetcher: any = useFetcher({
    key: storageKey,
  });

  const [feed, setFeed] = useState<Feed | undefined>(undefined);

  const getAndSetFeedData = async () => {
    const APP_VERSION_UUID: string = window.versionInfo?.APP_VERSION_UUID;

    const feedData = (await localforage.getItem(
      `${storageKey}-${APP_VERSION_UUID}`
    )) as Feed;

    setFeed(feedData || []);
  };

  useEffect(() => {
    if (!condition) return;
    getAndSetFeedData();
  }, [fetcher.data]);

  const feedCount: number = feed?.entry?.length || 0;
  const isLoading: boolean = fetcher.state === 'loading';
  const isUpdating: boolean = isLoading && feedCount > 0;

  return {
    feed,
    feedCount,
    isLoading,
    isUpdating,
  };
}
