import { useFetcher, useLoaderData } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { loader } from '~/routes/_index';
import { type FeedEntry } from '~/services/layout';
import logger from '~/services/logger';
import { track } from '~/services/analytics/index.client';
import debounce from 'lodash.debounce';
import { useIsLoggedIn } from './use-is-logged-in';
import { useGetStorageFeed } from './use-feed';

export const FAVORITE_DEBOUNCE_DELAY: number = 1000;

export type UseFavoriteActionReturnType = {
  isFavorite: boolean;
  favoriteAction: () => void;
  shouldRenderFavoriteAction: boolean;
  preventRenderByIsLoggedIn: boolean;
};

export function useFavoriteAction({
  entry,
}: {
  entry: FeedEntry;
}): UseFavoriteActionReturnType {
  const { isFavoritesSyncedFeed } = useLoaderData<typeof loader>();

  const { isLoggedIn, preventRenderByIsLoggedIn } = useIsLoggedIn();

  const fetcher: any = useFetcher({
    key: 'favorites',
  });

  const [isFavorite, setIsFavorite] = useState<boolean | null>(null);

  const entryId: string = entry?.id;

  const { feed: favoritesFeed, feedCount } = useGetStorageFeed({
    storageKey: 'favorites',
    condition: isFavoritesSyncedFeed,
  });

  useEffect(() => {
    const callSetIsFavorite =
      fetcher.state === 'idle' && isLoggedIn && favoritesFeed && feedCount > 0;

    if (!callSetIsFavorite) {
      if (isFavorite === null) setIsFavorite(false);
      return;
    }

    try {
      const isFav = favoritesFeed?.entry.some(
        (fav: FeedEntry) => fav.id === entryId
      );

      setIsFavorite(!!isFav);
    } catch (error: any) {
      logger.info(`useFavoriteAction: ${error.message}`);
    }
  }, [feedCount, fetcher.state]);

  const debouncedFavoriteAction = debounce(
    () => {
      const favAction = !!isFavorite ? 'removed' : 'added';

      const stringifyEntry: string = getStringifyEntry(entry);

      fetcher.submit(
        { favAction, stringifyEntry },
        {
          method: 'post',
          action: `/api/favorite-action`,
        }
      );

      track('favclicked789', { entryId, isFavorite: !isFavorite });
    },
    FAVORITE_DEBOUNCE_DELAY,
    {
      leading: true,
    }
  );

  const favoriteAction = () => {
    setIsFavorite(!isFavorite);
    debouncedFavoriteAction();
  };

  const shouldRenderFavoriteAction = [
    !preventRenderByIsLoggedIn,
    isLoggedIn,
    entryId,
    isFavorite !== null,
    isFavoritesSyncedFeed,
  ].every(Boolean);

  return {
    isFavorite: !!isFavorite,
    favoriteAction,
    shouldRenderFavoriteAction,
    preventRenderByIsLoggedIn,
  };
}

/**
 * Get the stringified entry object with error handling
 * @param entry - The entry object
 * @returns - The stringified entry object or empty string
 */
function getStringifyEntry(entry: any): string {
  try {
    return JSON.stringify(entry);
  } catch (error) {
    return '';
  }
}
